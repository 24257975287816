import { Box, Card, CardContent, CardMedia, Container, Typography } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { BgImage } from "gbimage-bridge"
import "./anchor-offset.css"

const PageTemplate2 = ({title, coverImage, children, location, imageCrop}) => {
  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "background-pattern4" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  const bgImage = getImage(data.file)
  
  return (
    <Layout location={location}>
      <Seo title={title || "title"} />
      <BgImage
        image={bgImage}
        style={{
          backgroundRepeat: "repeat",
          backgroundSize: "800px",
          backgroundPosition: "left top",
        }}
      >
        <Container maxWidth="md">
          <Box py={8}>
            <Card>
              {coverImage && (
                <CardMedia>
                  <GatsbyImage
                    image={getImage(coverImage)}
                    alt="Featured image"
                    style={{ height: "300px", width: "100%" }}
                    objectPosition={imageCrop || "center"}
                  />
                </CardMedia>
              )}
              <CardContent style={{ padding: 0 }}>
                <Box py={{xs: 3,md: 6}} px={{xs: 3, md: 8}}>
                  <Typography
                    align="center"
                    gutterBottom
                    color="primary"
                    variant="h1"
                  >
                    {title}
                  </Typography>
                  {children}
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Container>
      </BgImage>
    </Layout>
  )
}

export default PageTemplate2