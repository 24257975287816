import React from "react"
import PageTemplate2 from "../templates/page2"

const BlogPage = () => {

  return (
    <PageTemplate2 title="Announcements">
      <p>Blog will go here</p>
    </PageTemplate2>
  )
}

export default BlogPage
